import { type DetailedHTMLProps, type HTMLAttributes } from 'react';
import { type FCImage } from '@/components/ui/IconEmojies/typedefs';
import { type FCIcon } from '@/components/ui/icons/typedefs';
import { type RequireAtLeastOne } from '@/lib/helpers/utility-types';

export enum TagColor {
  Default = 'default',
  Neutral = 'neutral',
  Brand = 'brand',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Rose = 'rose',
  Magenta = 'magenta',
  Purple = 'purple',
  Teal = 'teal',
  Lime = 'lime',
}

export enum TagSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum TagMode {
  Accent = 'accent',
  Subtle = 'subtle',
  NoBackground = 'no-background',
}

type TagElementProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

interface CommonProps extends TagElementProps {
  size?: TagSize;
  color?: TagColor;
  dataQa?: string;
}

interface TagNoBackgroundProps extends CommonProps {
  mode: TagMode.NoBackground;
  IconElement: FCIcon | FCImage;
  text: string;
}

interface TagOtherModesProps extends CommonProps {
  mode?: Exclude<TagMode, TagMode.NoBackground>;
  IconElement?: FCIcon | FCImage;
  text?: string;
}

export type TagProps =
  | TagNoBackgroundProps
  | RequireAtLeastOne<TagOtherModesProps, 'IconElement' | 'text'>;
