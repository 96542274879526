import { useMemo, useRef } from 'react';
import {
  ACHIEVEMENTS_CATEGORIES_ORDER_WITH_SIZE,
} from '@/components/platform/Achievements/constants';
import {
  type AchievementsByCategoryWithSize,
} from '@/components/platform/Achievements/typedefs';
import {
  type PersonalAchievementCategory,
} from '@/controllers/graphql/generated';
import { usePersonalAchievementsByCategoriesQuery } from '@/components/platform/Achievements/graphql/generated/personalAchievementsByCategories.query.generated';
import { type PersonalAchievementsByCategoryFullFragment } from '@/components/platform/Achievements/graphql/generated/personalAchievementsByCategoryFull.fragment.generated';

type Options = {
  userId: number;
};
type Result = {
  achievementsByCategoryWithSize: AchievementsByCategoryWithSize[];
  achievementsCount: number;
  loading: boolean;
};

export const useAchievementsByCategoryWithSize = ({
  userId,
}: Options): Result => {
  const {
    data,
    loading,
  } = usePersonalAchievementsByCategoriesQuery({
    variables: {
      userId,
    },
  });

  const achievementsCount = useRef(0);

  const allAchievements = data?.personalAchievementsByCategories;

  const achievementsByCategoryWithSize = useMemo(() => {
    achievementsCount.current = 0;

    const allAchievementsMappedByCategory = allAchievements?.reduce<
      Map<
        PersonalAchievementCategory,
        PersonalAchievementsByCategoryFullFragment
      >
    >((acc, achievementsByCategory) => {
      const { category } = achievementsByCategory;

      acc.set(category, achievementsByCategory);

      return acc;
    }, new Map());

    return ACHIEVEMENTS_CATEGORIES_ORDER_WITH_SIZE.reduce<
      AchievementsByCategoryWithSize[]
    >(
      (acc, categoryWithSize) => {
        const [category, size] = categoryWithSize;
        const achievementsByCategory = allAchievementsMappedByCategory
          ?.get(category);

        if (!achievementsByCategory) {
          return acc;
        }

        achievementsCount.current += achievementsByCategory.achievements.length;

        acc.push({
          achievementsByCategory,
          size,
        });

        return acc;
      },
      [],
    );
  }, [
    allAchievements,
  ]);

  return {
    achievementsByCategoryWithSize,
    achievementsCount: achievementsCount.current,
    loading,
  };
};
