import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconArrowRight: FCIcon = (props) => (
  <BaseIcon
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-qa='right-arrow-button'
    {...props}
  >
    <path
      d="M8.29504 16.8851L12.875 12.2951L8.29504 7.70509L9.70504 6.29509L15.705 12.2951L9.70504 18.2951L8.29504 16.8851Z"
      fill="currentColor"
    />
  </BaseIcon>

);
