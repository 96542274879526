import { type FCAchievementBaseIcon } from './typedefs';
import { BaseAchievementIcon } from '.';

export const AchievementIconLockedShieldUp: FCAchievementBaseIcon = ({
  withShadow,
  shadowProps,
  iconHeight,
  iconWidth,
}) => (
  <BaseAchievementIcon
    withShadow={withShadow}
    shadowProps={shadowProps}
    iconHeight={iconHeight}
    iconWidth={iconWidth}
  >
    <svg
      width={iconWidth || 88}
      height={iconHeight || 88}
      viewBox="-5 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M45 0L6 11.3469V49C6 70.5391 23.4609 88 45 88C66.5391 88 84 70.5391 84 49V11.3469L45 0Z" fill="currentColor" />
    </svg>
  </BaseAchievementIcon>
);
