import { type FC } from 'react';
import NextImage, { type ImageProps } from 'next/image';
import { imageLoader } from '@/components/ui/Image/utils/imageLoader';
import { type ImageData } from '@/components/ui/Image/typedefs';
import { IconImage } from '@/components/ui/Image/IconImage';

type ImagePropsWithImageData = ImageProps & {
  imageData?: ImageData;
};

export const Image: FC<ImagePropsWithImageData> = (props) => {
  const {
    src,
    loader,
    imageData,
    ...rest
  } = props;

  if (imageData) {
    return (
      <IconImage
        {...rest}
        imageData={imageData}
      />
    );
  }

  return (
    <NextImage
      {...rest}
      loader={loader || imageLoader}
      src={src || ' '}
    />
  );
};
