import { type FCIcon } from '@/components/ui/icons/typedefs';
import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { Selectors } from '@/lib/selectors';
import { colorTokens } from '@/components/ui/colorTokens';

export const IconFire: FCIcon = (props) => {
  // Used to prevent id conflicts when using this component multiple times on the same page
  const gradientId = `paint0_linear_6_${Math.floor(Math.random() * 10000)}`;

  const fillColor = props.className?.includes(Selectors.Disabled)
    ? colorTokens['icon-tertiary']
    : `url(#${gradientId})`;

  return (
    <BaseIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.1045 8.41076C12.0333 5.62508 8.21961 5.47487 9.14064 1.42608C9.20886 1.12566 8.88821 0.893521 8.62895 1.05056C6.15241 2.51167 4.37175 5.44073 5.86586 9.27787C5.98867 9.59194 5.62026 9.88553 5.35418 9.6807C4.11932 8.74531 3.98969 7.40027 4.09885 6.43757C4.13979 6.08253 3.67586 5.91184 3.47801 6.20543C3.01408 6.9155 2.54333 8.06255 2.54333 9.78994C2.80259 13.6134 6.0296 14.7878 7.18942 14.938C8.84727 15.1496 10.6416 14.8424 11.931 13.6612C13.3501 12.3435 13.8686 10.2406 13.1045 8.41076ZM6.77325 11.8451C7.75568 11.6061 8.26054 10.896 8.39699 10.2679C8.62213 9.29152 7.74203 8.33565 8.33559 6.7926C8.56073 8.06937 10.5665 8.86821 10.5665 10.261C10.6211 11.9884 8.75176 13.47 6.77325 11.8451Z" fill={fillColor} />
      <defs>
        <linearGradient id={gradientId} x1="13.4567" y1="1" x2="2.54333" y2="15" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7246" />
          <stop offset="1" stopColor="#D12B17" />
        </linearGradient>
      </defs>
    </BaseIcon>
  );
};
