import {
  type MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  ACHIEVEMENT_ITEMS_LARGE_GAP,
  ACHIEVEMENT_ITEMS_SMALL_GAP,
  ACHIEVEMENT_ITEM_WIDTH,
  ACHIEVEMENT_COLS_COUNT_SMALL,
  ACHIEVEMENT_COLS_COUNT_LARGE,
} from '@/components/platform/Achievements/constants';

interface Result {
  itemsInRow: number;
  handleContainerResize: () => void;
}

const gapBreakpoint = (
  ACHIEVEMENT_COLS_COUNT_SMALL * ACHIEVEMENT_ITEM_WIDTH
  + ACHIEVEMENT_ITEMS_LARGE_GAP
);

export const useUpdateRowItemsOnResize = (
  ref: MutableRefObject<HTMLElement | null>,
): Result => {
  const [itemsInRow, setItemsInRow] = useState(ACHIEVEMENT_COLS_COUNT_LARGE);

  const handleContainerResize = useCallback(() => {
    const containerWidth = ref?.current?.offsetWidth;

    if (!containerWidth) {
      return;
    }

    const gapSize = containerWidth < gapBreakpoint
      ? ACHIEVEMENT_ITEMS_SMALL_GAP
      : ACHIEVEMENT_ITEMS_LARGE_GAP;

    const itemsCount = Math.min(
      ACHIEVEMENT_COLS_COUNT_LARGE,
      Math.floor(
        (containerWidth + gapSize) / (ACHIEVEMENT_ITEM_WIDTH + gapSize),
      ) || ACHIEVEMENT_COLS_COUNT_SMALL,
    );

    setItemsInRow(itemsCount);
  }, [
    ref,
  ]);

  return useMemo(() => ({
    itemsInRow,
    handleContainerResize,
  }), [
    itemsInRow,
    handleContainerResize,
  ]);
};
