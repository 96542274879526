import {
  type FC,
  memo,
  useCallback,
  useMemo,
} from 'react';

export type NaturalShadowProps = {
  x: number;
  y: number;
  blur: number;
  opacity: number;
};

type IconSizeProps = {
  width: number;
  height: number;
};

export const NaturalShadow: FC<NaturalShadowProps & IconSizeProps> = memo(({
  width,
  height,
  x,
  y,
  blur,
  opacity,
  children,
}) => {
  const minimumOffset = useMemo(() => blur * 1.5, [blur]);

  const deviation = useMemo(() => blur * 0.75, [blur]);

  const viewWidth = useMemo(() => (
    width + (minimumOffset + Math.abs(x)) * 2
  ), [width, minimumOffset, x]);

  const viewHeight = useMemo(() => (
    height + minimumOffset * 2 + Math.abs(y)
  ), [height, minimumOffset, y]);

  const getOffsetX = useCallback(
    (value: number): number => (
      minimumOffset + Math.abs(value)
    ),
    [minimumOffset],
  );

  const getOffsetY = useCallback(
    (value: number): number => (
      minimumOffset - (
        value < minimumOffset
          ? value
          : 0
      )
    ),
    [minimumOffset],
  );

  return (
    <svg
      width={viewWidth}
      height={viewHeight}
      viewBox={`0 0 ${viewWidth} ${viewHeight}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <filter id="naturalBlur" x="0" y="0" width="2" height="2">
        <feOffset in="SourceGraphic" dx={x} dy={y} />
        <feGaussianBlur stdDeviation={deviation} result="blur" />
        <feComponentTransfer in="blur" result="blurWithOpacity">
          <feFuncA type="linear" slope={opacity} />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode in="blurWithOpacity" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>

      <foreignObject
        x={getOffsetX(x)}
        y={getOffsetY(y)}
        width={width}
        height={height}
        filter="url(#naturalBlur)"
        shapeRendering="geometricPrecision"
      >
        {children}
      </foreignObject>
    </svg>
  );
});
