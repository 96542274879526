import { type FCAchievementBaseIcon } from './typedefs';
import { BaseAchievementIcon } from '.';

export const AchievementIconUnknown: FCAchievementBaseIcon = ({
  withShadow,
  shadowProps,
  iconHeight,
  iconWidth,
}) => (
  <BaseAchievementIcon
    withShadow={withShadow}
    shadowProps={shadowProps}
    iconHeight={iconHeight}
    iconWidth={iconWidth}
  >
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="87" height="87" rx="15.5" fill="var(--c-bg-neutral-tertiary)" />
      <rect x="0.5" y="0.5" width="87" height="87" rx="15.5" stroke="var(--c-border)" />
      <g filter="url(#filter0_d_51138_477578)">
        <circle cx="28" cy="28" r="26" transform="matrix(1 0 0 -1 16 72)" fill="var(--c-icon-quaternary)" />
      </g>
      <circle cx="28" cy="28" r="28" transform="matrix(1 0 0 -1 16 72)" fill="var(--c-icon-quaternary)" />
      <circle cx="22" cy="22" r="22" transform="matrix(1 0 0 -1 22 66)" fill="var(--c-bg-neutral-quaternary)" />
      <circle cx="15" cy="15" r="15" transform="matrix(1 0 0 -1 29 59)" fill="var(--c-bg-neutral-tertiary)" />
      <path d="M42.6102 47.5996C42.6247 46.5496 42.7451 45.784 42.9711 45.3027C43.1971 44.8215 43.6237 44.2965 44.2508 43.7277C44.8633 43.1736 45.3336 42.6595 45.6617 42.1855C45.9898 41.7116 46.1539 41.2048 46.1539 40.6652C46.1539 40.009 45.9352 39.4621 45.4977 39.0246C45.0602 38.5871 44.4477 38.3684 43.6602 38.3684C42.9018 38.3684 42.3185 38.5835 41.9102 39.0137C41.5018 39.4439 41.2029 39.8923 41.0133 40.359L39.1758 39.5496C39.4966 38.6892 40.0398 37.9564 40.8055 37.3512C41.5711 36.746 42.5227 36.4434 43.6602 36.4434C45.1185 36.4434 46.2414 36.848 47.0289 37.6574C47.8164 38.4668 48.2102 39.4402 48.2102 40.5777C48.2102 41.2777 48.0607 41.9121 47.7617 42.4809C47.4628 43.0496 46.9852 43.6475 46.3289 44.2746C45.6143 44.96 45.1841 45.485 45.0383 45.8496C44.8924 46.2142 44.8122 46.7975 44.7977 47.5996H42.6102ZM43.6602 53.0684C43.2372 53.0684 42.8763 52.9189 42.5773 52.6199C42.2784 52.321 42.1289 51.96 42.1289 51.5371C42.1289 51.1142 42.2784 50.7533 42.5773 50.4543C42.8763 50.1553 43.2372 50.0059 43.6602 50.0059C44.0831 50.0059 44.444 50.1553 44.743 50.4543C45.0419 50.7533 45.1914 51.1142 45.1914 51.5371C45.1914 51.96 45.0419 52.321 44.743 52.6199C44.444 52.9189 44.0831 53.0684 43.6602 53.0684Z" fill="var(--c-icon-quaternary)" />
      <defs>
        <filter id="filter0_d_51138_477578" x="4.54546" y="7.72727" width="81.4545" height="81.4545" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="16" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="multiply" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  </BaseAchievementIcon>
);
