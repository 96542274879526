import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Swiper, SwiperSlide, type SwiperProps } from 'swiper/react';
import { type Swiper as SwiperClass, Navigation } from 'swiper';
import { typography } from '@/components/ui/typography';
import { cn } from '@/lib';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import {
  AchievementsCategoryBlockedIcon,
} from '@/components/platform/Achievements/components/AchievementsCategoryBlockedIcon';
import {
  AchievementItem,
} from '@/components/platform/Achievements/AchievementItem';
import {
  ACHIEVEMENT_ITEMS_SMALL_GAP,
  ACHIEVEMENT_ITEMS_LARGE_GAP,
  ACHIEVEMENT_COLS_COUNT_SMALL,
  ACHIEVEMENT_COLS_COUNT_MEDIUM,
} from '@/components/platform/Achievements/constants';
import {
  type AchievementBlockedReasonTranslateKey,
} from '@/components/platform/Achievements/typedefs';
import { useStateRef } from '@/hooks/useStateRef';
import { Button } from '@/components/ui/Button';
import { IconArrowLeft } from '@/components/ui/icons/IconArrowLeft';
import { IconArrowRight } from '@/components/ui/icons/IconArrowRight';
import { Selectors } from '@/lib/selectors';
import { AchievementsCategoryHarnessSelectors } from '@/components/platform/Achievements/components/AchievementsCategory/AchievementsCategory.harness';
import { type AchievementFullFragment } from '@/modules/achievements/communication/generated/achievementFull.fragment.generated';
import styles from './AchievementsCategory.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';

export interface AchievementsCategoryProps {
  achievements: AchievementFullFragment[];
  category: string;
  maxItemsCount: number;
  blockedStatus?: {
    isBlocked: boolean;
    blockedReasonTranslationKey?: AchievementBlockedReasonTranslateKey | null;
  };
  onAchievementClick: (achievement: AchievementFullFragment) => void;
}

const swiperModules = [Navigation];

export const AchievementsCategory: FC<AchievementsCategoryProps> = ({
  achievements,
  category,
  maxItemsCount,
  blockedStatus,
  onAchievementClick,
}) => {
  const { t } = useTranslation([I18N_CODES.achievements]);
  const [prevButton, setPrevButtonRef] = useStateRef<HTMLButtonElement>();
  const [nextButton, setNextButtonRef] = useStateRef<HTMLButtonElement>();
  const [shouldShowNavigation, setShouldShowNavigation] = useState(false);

  const breakpoints: Record<string, SwiperProps> = useMemo(() => ({
    1200: {
      slidesPerView: maxItemsCount,
      spaceBetween: ACHIEVEMENT_ITEMS_LARGE_GAP,
    },
    640: {
      slidesPerView: ACHIEVEMENT_COLS_COUNT_MEDIUM,
      spaceBetween: ACHIEVEMENT_ITEMS_LARGE_GAP,
    },
    320: {
      slidesPerView: ACHIEVEMENT_COLS_COUNT_SMALL,
      spaceBetween: ACHIEVEMENT_ITEMS_SMALL_GAP,
    },
  }), [
    maxItemsCount,
  ]);

  useEffect(() => {
    setShouldShowNavigation(
      achievements.length > maxItemsCount,
    );
  }, [
    achievements.length,
    maxItemsCount,
  ]);

  const handleSwiperResize = useCallback(
    ({ currentBreakpoint }: SwiperClass) => {
      const slidesPerView = breakpoints[currentBreakpoint as string]
        ?.slidesPerView;

      if (!slidesPerView || slidesPerView === 'auto') {
        return;
      }

      setShouldShowNavigation(achievements.length > slidesPerView);
    },
    [
      achievements.length,
      breakpoints,
    ],
  );

  const handleItemClick = useCallback(
    (
      achievement: AchievementFullFragment,
    ) => {
      if (blockedStatus?.isBlocked) {
        return;
      }

      onAchievementClick(achievement);
    },
    [
      blockedStatus?.isBlocked,
      onAchievementClick,
    ],
  );

  const navigation = useMemo(() => ({
    nextEl: nextButton,
    prevEl: prevButton,
  }), [
    nextButton,
    prevButton,
  ]);

  if (!achievements.length) {
    return null;
  }

  const blockedReasonTranslation = blockedStatus?.blockedReasonTranslationKey
    ? t(`${I18N_CODES.achievements}:${blockedStatus.blockedReasonTranslationKey}`)
    : '';

  return (
    <div
      data-qa={AchievementsCategoryHarnessSelectors.DataQa}
      className={styles.categoryWrapper}
    >
      <div className={styles.titleWrapper}>
        <h3
          data-qa={AchievementsCategoryHarnessSelectors.Title}
          className={cn(typography.platformH2, styles.title)}
        >
          {t(`${I18N_CODES.achievements}:category.${category}`)}
        </h3>

        <div
          data-qa={AchievementsCategoryHarnessSelectors.Navigation}
          className={cn(
            styles.buttonContainer,
            {
              [Selectors.Hidden]: !shouldShowNavigation,
            },
          )}
        >
          <Button
            className={styles.button}
            mode={Button.mode.Secondary}
            size={Button.size.Small}
            LeftIcon={IconArrowLeft}
            ref={setPrevButtonRef}
          />

          <Button
            className={styles.button}
            mode={Button.mode.Secondary}
            size={Button.size.Small}
            LeftIcon={IconArrowRight}
            ref={setNextButtonRef}
          />
        </div>

        {blockedStatus?.isBlocked && (
          <AchievementsCategoryBlockedIcon
            blockedReasonTranslation={blockedReasonTranslation}
          />
        )}
      </div>

      <Swiper
        className={styles.achievementsSlider}
        modules={swiperModules}
        navigation={navigation}
        slidesPerView={maxItemsCount}
        spaceBetween={ACHIEVEMENT_ITEMS_LARGE_GAP}
        speed={300}
        grabCursor
        breakpoints={breakpoints}
        onResize={handleSwiperResize}
        shortSwipes
      >
        {achievements.map((achievement) => (
          <SwiperSlide
            className={styles.achievementItem}
            key={achievement.id}
          >
            <AchievementItem
              achievement={achievement}
              userAchievement={achievement.userAchievement}
              onClick={() => handleItemClick(achievement)}
              className={cn(
                { [styles.achievementItemBlocked]: blockedStatus?.isBlocked },
              )}
              textTruncated={false}
              showToolTip={false}
              blocked={blockedStatus?.isBlocked}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
