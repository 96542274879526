import {
  type FC,
  useState,
  useMemo,
  useCallback,
} from 'react';
import NextImage, { type ImageProps } from 'next/image';
import { iconImageLoader } from '@/components/ui/Image/utils/imageLoader';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { type ImageData } from '@/components/ui/Image/typedefs';

type ImagePropsWithourSrc = Omit<ImageProps, 'src'>;

interface Props extends ImagePropsWithourSrc {
  imageData: ImageData;
  /** Use this param for gif images */
  unoptimized?: boolean;
}

/** Use this component for the icons that consist of raster images */
export const IconImage: FC<Props> = (props) => {
  const {
    imageData,
    unoptimized,
    ...rest
  } = props;

  const {
    fallback,
    s3Path,
  } = imageData;

  const [hasError, setHasError] = useState(false);

  const loader = useMemo(() => {
    // use static image if there are no images on the media bucket
    if (hasError) {
      return undefined;
    }

    return iconImageLoader;
  }, [hasError]);

  const features = useFeatures();
  const isReworkEnabled = features.isEnabled(Features.ImageStoringRework);

  const imageSrc = hasError
    ? fallback
    : s3Path;

  const handleError = useCallback(() => setHasError(true), []);

  return (
    <>
      {isReworkEnabled
        ? (
          <NextImage
            {...rest}
            loader={loader}
            src={imageSrc}
            onError={handleError}
            unoptimized={hasError ?? unoptimized}
          />
        )
        : (
          <NextImage
            {...rest}
            src={fallback}
            unoptimized
          />
        )}
    </>
  );
};
