import { type ImageLoaderProps } from 'next/image';
import getConfig from 'next/config';
import { EMPTY_OBJECT } from '@/constants';

const buildImageUrlFromSrc = (src: string): string => {
  const srcMap = src.split('/');

  const url = srcMap.length > 2
    ? srcMap.slice(3).join('/')
    : src;

  return url;
};

const S3_IMAGE_URL_PATTERN = /https:\/\/mate-academy-images(-dev|-stage)?\.s3\..+\.amazonaws\.com\/.+?\.(?!gif$)[a-zA-Z0-9]+/;

const validateS3ImageUrl = (uri: string): boolean => (
  S3_IMAGE_URL_PATTERN.test(uri)
);

const getRuntimeConfig = (): Record<string, unknown> => {
  const { publicRuntimeConfig = EMPTY_OBJECT } = getConfig() || EMPTY_OBJECT;

  const {
    IMAGE_HANDLER_ENDPOINT: endpoint,
    STATIC_FILES_ENDPOINT: staticFilesEndpoint = 'https://static.mate.academy',
    NODE_ENV: nodeEnv,
  } = publicRuntimeConfig;

  return { endpoint, staticFilesEndpoint, nodeEnv };
};

export const imageLoader = ({ src, width }: ImageLoaderProps): string => {
  const { endpoint, nodeEnv } = getRuntimeConfig();

  const url = buildImageUrlFromSrc(src);

  if (nodeEnv === 'test') {
    return `${endpoint}/${url}`;
  }

  return `${endpoint}/fit-in/${width}x${width}/${url}`;
};

export const rawImageLoader = ({ src }: {src: string}): string => {
  if (src.endsWith('.gif')) {
    return src;
  }

  const { endpoint, nodeEnv } = getRuntimeConfig();

  const url = buildImageUrlFromSrc(src);

  if (nodeEnv === 'test') {
    return `${endpoint}/${url}`;
  }

  return validateS3ImageUrl(src)
    ? `${endpoint}/${url}`
    : src;
};

export const rawFileLoader = ({ url }: {url: string}): string => {
  const { staticFilesEndpoint } = getRuntimeConfig();

  const uri = buildImageUrlFromSrc(url);

  return `${staticFilesEndpoint}/${uri}`;
};

/** Use this loader for icon images that stored in "assets" folder */
export const iconImageLoader = ({ src, width }: ImageLoaderProps): string => {
  const { endpoint } = getRuntimeConfig();

  return `${endpoint}/fit-in/${width}x${width}/assets/${src}`;
};
