import { usePlatformTheme } from '@/components/common/ThemeContext';
import { type AchievementIconOptions } from '@/components/platform/Achievements/components/AchievementIcon';

export const useThemedLockedIcon = (
  icons: Pick<AchievementIconOptions, 'iconLockedUrl' | 'iconLockedDarkUrl'>,
): string | undefined => {
  const { iconLockedUrl, iconLockedDarkUrl } = icons;
  const { isDarkMode } = usePlatformTheme();

  return (
    isDarkMode
      ? iconLockedDarkUrl
      : iconLockedUrl
  );
};
