import { BaseIcon } from '@/components/ui/icons/BaseIcon';
import { type FCIcon } from '@/components/ui/icons/typedefs';

export const IconLock: FCIcon = (props) => (
  <BaseIcon
    data-qa="lock-icon"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.9997 10.6663H22.6663V7.99967C22.6663 4.31967 19.6797 1.33301 15.9997 1.33301C12.3197 1.33301 9.33301 4.31967 9.33301 7.99967V10.6663H7.99967C6.53301 10.6663 5.33301 11.8663 5.33301 13.333V26.6663C5.33301 28.133 6.53301 29.333 7.99967 29.333H23.9997C25.4663 29.333 26.6663 28.133 26.6663 26.6663V13.333C26.6663 11.8663 25.4663 10.6663 23.9997 10.6663ZM15.9997 22.6663C14.533 22.6663 13.333 21.4663 13.333 19.9997C13.333 18.533 14.533 17.333 15.9997 17.333C17.4663 17.333 18.6663 18.533 18.6663 19.9997C18.6663 21.4663 17.4663 22.6663 15.9997 22.6663ZM20.133 10.6663H11.8663V7.99967C11.8663 5.71967 13.7197 3.86634 15.9997 3.86634C18.2797 3.86634 20.133 5.71967 20.133 7.99967V10.6663Z"
      fill="currentColor"
    />
  </BaseIcon>
);
