import { memo } from 'react';
import {
  ACHIEVEMENT_ICON_HEIGHT,
  ACHIEVEMENT_ICON_WIDTH,
  type FCAchievementBaseIcon,
  defaultShadowProps,
} from '@/components/ui/icons/achievements';
import { NaturalShadow } from '@/components/ui/NaturalShadow';

export const BaseAchievementIcon: FCAchievementBaseIcon = memo(
  (props) => {
    const {
      withShadow = true,
      shadowProps,
      children,
      iconWidth,
      iconHeight,
    } = props;

    return withShadow
      ? (
        <NaturalShadow
          {...defaultShadowProps}
          {...shadowProps}
          width={iconWidth || ACHIEVEMENT_ICON_WIDTH}
          height={iconHeight || ACHIEVEMENT_ICON_HEIGHT}
        >
          {children}
        </NaturalShadow>
      )
      : (
        <>
          {children}
        </>
      );
  },
);
