import dynamic from 'next/dynamic';
import {
  type FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  maxItemsCount,
  blockedReasonTranslationKeys,
} from '@/components/platform/Achievements/constants';
import {
  type AchievementsByCategoryWithSize,
} from '@/components/platform/Achievements/typedefs';
import {
  type AchievementsCategoryProps,
} from '@/components/platform/Achievements/components/AchievementsCategory';
import {
  AchievementItemModal,
} from '@/components/platform/Achievements/components/AchievementItemModal';
import i18Next from '@/middleware/i18n';
import { AchievementsEvents } from '@/controllers/achievements/achievements.events';
import { AchievementsContainerHarnessSelectors } from '@/components/platform/Achievements/components/AchievementsContainer/AchievementsContainer.harness';
import { type AchievementFullFragment } from '@/modules/achievements/communication/generated/achievementFull.fragment.generated';
import { useCheckOutNewUserAchievementMutation } from '@/components/platform/Achievements/graphql/generated/checkOutNewUserAchievement.mutation.generated';
import styles from './AchievementsContainer.module.scss';

const AchievementsCategory = dynamic<AchievementsCategoryProps>(
  () => import('@/components/platform/Achievements/components/AchievementsCategory')
    .then((mod) => mod.AchievementsCategory),
  {
    ssr: false,
  },
);

type Props = {
  achievementsByCategoryWithSize: AchievementsByCategoryWithSize[];
};

export const AchievementsContainer: FC<Props> = ({
  achievementsByCategoryWithSize,
}) => {
  const [selectedAchievement, setSelectedAchievement] = useState<
    AchievementFullFragment
  >();
  const [isModalOpened, setIsModalOpened] = useState(false);
  const router = i18Next.useRouter();

  const [checkOutUserAchievement] = useCheckOutNewUserAchievementMutation();

  const handleOpenModal = useCallback(async (
    achievement: AchievementFullFragment,
  ) => {
    setIsModalOpened(!isModalOpened);
    setSelectedAchievement(achievement);

    const userAchievement = achievement?.userAchievement;
    const isNew = !!userAchievement?.isNew;
    const userAchievementId = userAchievement?.id || 0;

    if (isNew && userAchievement) {
      await checkOutUserAchievement({
        variables: {
          id: userAchievementId,
        },
        optimisticResponse: {
          checkOutNewUserAchievement: {
            ...userAchievement,
            isNew: false,
          },
        },
      });
    }

    AchievementsEvents.sendEvent(
      AchievementsEvents.events.pageAchievementModalOpened,
      {
        achievement: achievement?.name,
      },
    );
  }, [
    checkOutUserAchievement,
    isModalOpened,
  ]);

  const handleCloseModal = useCallback(() => {
    setIsModalOpened(false);
  }, []);

  useEffect(() => {
    const { selectedId } = router.query;

    if (selectedId) {
      achievementsByCategoryWithSize.some((record) => {
        const { achievementsByCategory } = record;

        if (!achievementsByCategory.blockedReason) {
          const foundAchievement = achievementsByCategory.achievements
            .find(({ id }) => id === +selectedId);

          if (foundAchievement) {
            handleOpenModal(foundAchievement);

            return true;
          }
        }

        return false;
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        data-qa={AchievementsContainerHarnessSelectors.DataQa}
        className="grid-container"
      >
        <div className="grid-x grid-margin-x">
          <div className="cell large-12">
            <div className={styles.wrapper}>
              {achievementsByCategoryWithSize.map(({
                achievementsByCategory,
                size,
              }) => {
                const {
                  category,
                  achievements,
                  blockedReason,
                } = achievementsByCategory;

                const blockedReasonTranslationKey = blockedReason && (
                  blockedReasonTranslationKeys[blockedReason]
                );

                return (
                  <div
                    className={styles[size]}
                    key={category}
                  >
                    <AchievementsCategory
                      achievements={achievements}
                      category={category}
                      maxItemsCount={maxItemsCount[size]}
                      blockedStatus={{
                        isBlocked: !!blockedReason,
                        blockedReasonTranslationKey,
                      }}
                      onAchievementClick={handleOpenModal}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <AchievementItemModal
        achievement={selectedAchievement}
        isModalOpened={isModalOpened}
        onClose={handleCloseModal}
      />
    </>
  );
};
