import { type DetailedHTMLProps, type HTMLAttributes } from 'react';
import { type AchievementBaseIconProps, type FCAchievementBaseIcon } from '@/components/ui/icons/achievements/typedefs';
import {
  type UserAchievement,
  type PersonalAchievementCategory,
  type Achievement,
} from '@/controllers/graphql/generated';
import { type PersonalAchievementsByCategoryFullFragment } from '@/components/platform/Achievements/graphql/generated/personalAchievementsByCategoryFull.fragment.generated';

export enum LockedIconName {
  Unknown = 'UNKNOWN',
  LockedCircle = 'LOCKED_CIRCLE',
  LockedHexagon = 'LOCKED_HEXAGON',
  LockedShield = 'LOCKED_SHIELD',
  LockedShieldUp = 'LOCKED_SHIELD_UP',
  LockedStar = 'LOCKED_STAR',
  LockedShieldRotated = 'LOCKED_SHIELD_ROTATED',
}

export type LockedAchievementIcons = Record<
  LockedIconName,
  FCAchievementBaseIcon
>;

export type AchievementItemSettings = {
  achievement?: Pick<Achievement, 'name' | 'category' | 'rarity' | 'iconLocked' | 'iconLockedDark' | 'iconUnlocked'>;
  userAchievement?: Pick<UserAchievement, 'isNew' | 'unlocked'> | null;
  showToolTip?: boolean;
  showName?: boolean;
  showNewBadge?: boolean;
  showRarityBadge?: boolean;
  showCategory?: boolean;
  textTruncated?: boolean;
  customIcon?: FCAchievementBaseIcon;
  blocked?: boolean;
} & AchievementBaseIconProps;

export type AchievementItemProps = DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLDivElement
> & AchievementItemSettings;

export enum AchievementBlockedReasonTranslateKey {
  OnlyMateStudents = 'category_blocked_only_mate_students',
  OnlyPTStudents = 'category_blocked_only_parttime_students',
  OnlyFTStudents = 'category_blocked_only_fulltime_students',
  UnderDevelopment = 'category_blocked_under_development',
}

export enum CategorySize {
  Half = 'halfWidth',
  Full = 'fullWidth',
}

export type PageCategoriesWithSize = (
  [PersonalAchievementCategory, CategorySize][]
);

export type AchievementsByCategoryWithSize = {
  achievementsByCategory: PersonalAchievementsByCategoryFullFragment;
  size: CategorySize;
};

export enum AchievementsCSSVariable {
  ColumnsCountSmall = '--achievements-columns-small',
  ColumnsCountMedium = '--achievements-columns-medium',
  ColumnsCountLarge = '--achievements-columns-large',
  ItemWidth = '--achievement-item-width',
  ItemGapSmall = '--achievement-items-small-gap',
  ItemGapLarge = '--achievement-items-large-gap',
}

export type AchievementsPageCSSVariables = Record<
  AchievementsCSSVariable,
  string | number
>;

export enum ChatGuruChatType {
  Community = 'Community',
  QnA = 'QnA',
}

export enum AchievementTagType {
  New = 'NEW',
  Rare = 'RARE',
}
